/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */

import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnChanges, OnInit, Optional, input } from '@angular/core';
import { WaytrIconsService } from './waytr-icon.service';

@Component({
  selector: 'waytr-icon',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./waytr-icon.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaytrIcon implements OnInit, OnChanges {
  private svgIcon!: SVGElement;

  name = input<string>('');
  width = input<string>('');
  height = input<string>('');
  fill = input<string>('');

  constructor(
    private element: ElementRef,
    private waytrIconsService: WaytrIconsService,
    @Optional() @Inject(DOCUMENT) private readonly document: any,
  ) {}

  ngOnInit() {
    this.renderIcon();
  }

  ngOnChanges() {
    this.renderIcon();
  }

  private renderIcon() {
    if (this.name) {
      const svgData = this.waytrIconsService.getIcon(this.name());

      if (svgData) {
        if (this.svgIcon) {
          this.element.nativeElement.removeChild(this.svgIcon);
        }

        if (this.element.nativeElement?.children?.length < 1) {
          this.svgIcon = this.svgElementFromString(svgData);
          this.setDimensions(this.svgIcon);
          this.setFill(this.svgIcon);
          this.element.nativeElement.appendChild(this.svgIcon);
        }
      }
    }
  }

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }

  private setDimensions(svgElement: SVGElement) {
    if (this.width()) {
      svgElement.setAttribute('width', this.width());
    }

    if (this.height()) {
      svgElement.setAttribute('height', this.height());
    }
  }

  private setFill(svgElement: SVGElement) {
    if (this.fill) {
      const paths = svgElement.querySelectorAll('path, circle, ellipse, polygon');
      paths.forEach(path => path.setAttribute('fill', this.fill()));
    }
  }
}
